/* eslint-disable react/jsx-child-element-spacing */
import React from 'react'
import PropTypes from 'prop-types'
import ABanner from '../../Assets/images/png/banner/bannerPink.png'
import BBanner from '../../Assets/images/png/banner/bannerYellow.png'
import CBanner from '../../Assets/images/png/banner/bannerPurple.png'
import buenfinLogo from '../../Assets/images/png/banner/buenfin-logo.png'
import './Banner.css'

/**
 * Banner component
 * @author John Casas <john.casas@mymoons.co>
 * @version 1.0.0
 * @date 2021-09-22
 *
 * @param {{title: string}} - Title of the banner
 * @param {{date: string}} - Expiration date of the counter banner
 * @return {JSX.Element} Component
 */

/**
 * calculateTimeLeft
 * Calculate time left for the expiration date
 * @param {String} date expiration promo
 * @returns {Object} time left
 */
const calculateTimeLeft = (date) => {
  const difference = Number(new Date(date)) - Number(new Date())
  let timeLeft = {}

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60)
    }
  }

  return timeLeft
}

/**
 * Image
 * Component Image
 * @param {String} src image
 * @returns {Object} Image
 */
const Image = ({ src }) => (
  <img
    src={src}
    alt="Moons"
    width={52}
    height={52}
  />
)

/**
 * Banner
 * Component Banner
 * @param {String} title banner
 * @param {String} expiration date
 * @returns {Object} Component banner
 */
const Banner = ({ title, date,  activePaymentAppoinment, desactivePaymentAppoinment, buenFinPromo }) => {
  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft(date))
  const [cookieExist, setCookieExist] = React.useState(false)

  React.useEffect(() => {
    if (timeLeft.minutes === null
      || timeLeft.minutes === undefined
      || timeLeft.seconds === null
      || timeLeft.seconds === undefined
      || timeLeft === 0) {
      activePaymentAppoinment()
    } else {
      desactivePaymentAppoinment(timeLeft.minutes, timeLeft.seconds)
      setCookieExist(true)
    }

    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(date))
    }, 1000)
    // Clear timeout if the component is unmounted

    return () => {
      clearTimeout(timer)
    }
  },[timeLeft])

  return (
    <div id="banner">
      <Image src={ABanner} />
      <Image src={BBanner} />
      <Image src={CBanner} />
      <div className="banner-content">
        {buenFinPromo ? (
          <img
            src={buenfinLogo}
            alt="Moons Buen fin"
            width={29}
            height={25}
            className="buenfinLogo"
          />
        ) : null}
        {buenFinPromo ? (
          <span className="buenfin--title">
            <b>¡</b>
            Aprovecha tu descuento de&nbsp;
            <b>
              $4,000 del 10 al 16 de noviembre
            </b>
             &nbsp;en el Buen Fin
            <b>!</b>
          </span>
        ) : (
          <span className="title">{title}</span>
        )}
        {date && (
          <div className="timer-container">
            <span>
              {timeLeft.minutes && cookieExist ? timeLeft.minutes : '00'
              }
              m
            </span>
            <span className="separator">:</span>
            <span>
              {timeLeft.seconds && cookieExist ? timeLeft.seconds : '00'
              }
              s
            </span>
          </div>
        )}
      </div>
      <Image src={CBanner} />
      <Image src={BBanner} />
      <Image src={ABanner} />
    </div>
  )
}

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string,
}

Banner.defaultProps = {
  date: '',
}

export default Banner
