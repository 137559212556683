/* eslint-disable require-jsdoc */
/* eslint-disable radix */
import React from 'react'
import Cookies from 'js-cookie'
import { checkPropTypes } from 'prop-types'
import styles from './Shell.module.css'
import Header from '../Header/Header'
import Banner from '../NewBanner'
import { paramsObject } from '../../Utils/createUrlParams'
import {
  FREE_APPOINMENT_VARIANT_MEDELLIN,
  FREE_APPOINMENT_VARIANT_BUCARAMANGA,
  PROMO_APPOINMENT_VARIANT_MEDELLIN,
  PROMO_APPOINMENT_VARIANT_BUCARAMANGA,
  servicesList,
} from '../../Utils/constants'
import CandidateModal from '../CadidateModal/CandidateModal'
import { getAppointmentPrices } from "../../Api/Product";

/**
 * Shell component.
 * @returns {void} .
 */
const queryParams = paramsObject()
const { Journey_Variant, transactionId } = queryParams
const cookieTimer = parseInt(Cookies.get('timer'))
const toggleBannerPromo = (variant) => {
  let titleBanner = ''
  switch (variant) {
  case FREE_APPOINMENT_VARIANT_MEDELLIN:
  case FREE_APPOINMENT_VARIANT_BUCARAMANGA:
    titleBanner = 'Aprovecha nuestra promo y agenda tu cita ¡🔥 GRATIS 🔥!'
    break
  case PROMO_APPOINMENT_VARIANT_MEDELLIN:
  case PROMO_APPOINMENT_VARIANT_BUCARAMANGA:
    titleBanner = '¡Aprovecha nuestro descuento! Agenda tu cita por $30.000'
    break
  default:
    break
  }
  return titleBanner
}

const isThereANewService = (servicesOriginArray) => {
  let newService = false
  if (servicesOriginArray) {
    newService = servicesOriginArray.map((service) => servicesList.includes(service))
    return newService[0]
  }
  return false
}

const Shell = ({
  children,
  headerDisabled,
  activePaymentAppoinment,
  desactivePaymentAppoinment,
  country,
  patient,
  closeCandidateModal,
  isFree,
}) => {
  const [timer, setTimer] = React.useState(0)
  const [modal, setModal] = React.useState(false)
  const [modalPrices, setModalPrices] = React.useState(null)
  const header = headerDisabled === true ? null : <Header />
  const $body = document.querySelector('body')
  const $html = document.querySelector('html')
  let scrollPosition = 0
  React.useEffect(() => {
    if (!closeCandidateModal) {
      setModal(true)
    } else {
      setModal(false)
    }
  }, [closeCandidateModal])

  React.useEffect(async () => {
    let prices = await getAppointmentPrices(country)
    setModalPrices(prices);
    if (modal && ((patient.Scan_Method_Paid=="Free Appointment" || !patient.Scan_Method_Paid) && !transactionId) && !isThereANewService(patient.Service_Origin)) {
      $html.style.height = '100vh'
      scrollPosition = window.pageYOffset
      $body.style.overflow = 'hidden'
      $body.style.position = 'fixed'
      $body.style.top = `-${scrollPosition}px`
      $body.style.width = '100%'
      return
    }

    $html.style.removeProperty('height')
    $body.style.removeProperty('overflow')
    $body.style.removeProperty('position')
    $body.style.removeProperty('top')
    $body.style.removeProperty('width')
    window.scrollTo(0, scrollPosition)
  }, [patient.Scan_Method_Paid, modal])

  React.useEffect(() => {
    const cookieTimer = parseInt(Cookies.get('timer'))
    setTimer(cookieTimer)
  }, [Cookies])

  return (
    <div className={styles.Container}>      
      {Journey_Variant === FREE_APPOINMENT_VARIANT_MEDELLIN
      || Journey_Variant === FREE_APPOINMENT_VARIANT_BUCARAMANGA
      || Journey_Variant === PROMO_APPOINMENT_VARIANT_MEDELLIN
      || Journey_Variant === PROMO_APPOINMENT_VARIANT_BUCARAMANGA ? (
          <Banner
          title={toggleBannerPromo(Journey_Variant)}
          date={
              !isNaN(timer)
            && (Journey_Variant === FREE_APPOINMENT_VARIANT_MEDELLIN
              || Journey_Variant === FREE_APPOINMENT_VARIANT_BUCARAMANGA)
                ? timer
                : null
            }
          activePaymentAppoinment={activePaymentAppoinment}
          desactivePaymentAppoinment={desactivePaymentAppoinment}
        />
        ) : null}

      {header}
      <div className={styles.ContainerChildren}>{children}</div>
    </div>
  )
}

export default Shell
