const ecommerce = `${process.env.REACT_APP_BASIC_ECOMERCE_URL}/products/key`;


export const getProductByKey = async (productKey) => {
  const {
    REACT_APP_VALIDATE_CUSTOMER_USER: customerUser,
    REACT_APP_VALIDATE_CUSTOMER_PASS: customerPass,
  } = process.env;

  const user = customerUser;
  const password = customerPass;
  const auth = `${user}:${password}`;
  const authEncoded = `Basic ${Buffer.from(auth).toString("base64")}`;

  try {
    const apiResponse = await fetch(
      `${ecommerce}/${productKey}`,
      {
        method: "GET",
        headers: {
          Authorization: authEncoded,
          "Content-Type": "application/json",
        }
      }
    );

    const data = await apiResponse.json();
    return data;
  } catch {
    return false;
  }
};

export const getAppointmentPrices = async (country) => {
    const countryData = {
        México: {
          code:"MX",
          currency:"MXN"
        },
        Colombia: {
          code:"CO",
          currency:"COP"
        },
        Peru: {
          code:"PE",
          currency:"PEN"
        }
    };
    const product = await getProductByKey('appointment')
    const prices = product?.masterData?.current?.masterVariant?.prices;
    var currentPrice,currentDiscount,countryPrice;
    if (prices) {
    countryPrice = prices.filter(price => {
        return price.country === countryData[country].code
    })
    currentPrice = formatPrice(country,countryPrice[0]?.value?.centAmount/100)
    currentDiscount = formatPrice(country,countryPrice[0]?.discounted?.value?.centAmount/100)

    return {currentPrice,currentDiscount}
    }
} 

export const formatPrice = (country,price) => {
  if (country === 'México') {
    return `$${
      price
        ? new Intl.NumberFormat("es-MX").format(
            parseFloat(
              price
            ).toFixed(2)
          )
        : null
    } MXN`
  }else if(country === 'Colombia'){
    return `$${
      price
        ? new Intl.NumberFormat("es-CO").format(
            parseFloat(
              price
            ).toFixed(2)
          )
        : ""
    } COP`
  }else if(country === 'Peru'){
    return  `S/ ${
      price
        ? new Intl.NumberFormat("es-PE").format(
            parseFloat(
              price
            ).toFixed(2)
          )
        : ""
    }`
  }else {
    return 0
  }
}

