export const setTrackingPatient = async (patient,data) => {
  if (patient && patient.CustomerId) {

    const properties = {
      customerId: patient.CustomerId
    }
    if (patient.Email) {
      properties.email = patient.Email
    }
    if (patient.Phone) {
      properties.phone = patient.Phone
    }
    if (patient.Patient_Name) {
      properties.name = patient.Patient_Name
    }
    if(window.freshpaint){
      try{
        window.localStorage.setItem('CustomerId', patient.CustomerId)
        window?.dataLayer?.push({'event':'loadPixel'});
        window.freshpaint.identify(patient.CustomerId, properties);
        window.freshpaint.track(data.name, data.data)
      }catch(err){
        console.log(err)
      }
    }
  }
}
