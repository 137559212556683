import React from 'react'
import { withRouter } from 'react-router-dom'
import Popup from 'reactjs-popup'
import { Experiment, Variant } from 'react-optimize'
import styles from './Branches.module.css'
import messages from './Branches.messages'
import Utils from '../../Utils/utils'
import implant from '../../Assets/images/svg/implant.svg'
import discount from '../../Assets/images/svg/discount.svg'
import 'reactjs-popup/dist/index.css'
import './styles-pop-up.css'
import modalImage from '../../Assets/images/png/modal.png'
import queryString from 'query-string';
import { setTrackingPatient } from '../../Utils/tracking'

/**
 * Branches Component
 * @return {React.Component} all smile centers
 */
class Branches extends React.Component {
  /**
   * Constructor
   * @param {*} props .
   */
  constructor(props) {
    super(props)

    let search = {}
    if(props && props.history.location && props.history.location.search){
      search = queryString.parse(props.history.location.search)
      if(search.state){
        delete search['state']
      }
    }
    props.history.push("?" + new URLSearchParams(search).toString())

    this.state = {
      branches: {}
    }
  }

  trackEvent(branch){
    const { setCurrentBranch, patient} = this.props
    setCurrentBranch(branch);
    let data = { 
      distinct_id: patient.CustomerId,
      customer_id: patient.CustomerId,
      country_ops: patient.Country_Ops,
      center_name: branch.Center_Name,
      platform:'appointment_platform_v2'
    }
    setTrackingPatient(patient,{name:'Old Appointment Platform - Center Selected', data})
  }

  /**
   * Component Did Mount
   * @return {void}
   */
  componentDidMount = () => {
    const { items } = this.props 
    items && this.mapBrachByState(items)
    // window.gtag('event', 'optimize.callback', {
    //   name: 'lmO2jJwnRRKDDA88i8cKxw',
    //   callback: (value) => {
    //     if (value === '1') {
    //       window.freshpaint.track('MX - popUpTelemedicine', { variant: 'new' })
    //     } else {
    //       window.freshpaint.track('MX - popUpTelemedicine', { variant: 'original' })
    //     }
    //   },
    // })
  }

  /**
   * Map Brach By State
   * @param {Object} items .
   * @return {void}
   */
  mapBrachByState = (items) => {
    const branches = {}
    Array.isArray(items) && items.map((branch) => {
      if (!branches[branch.State]) {
        branches[branch.State] = []
      }
      branches[branch.State].push(branch)
      return ''
    })
    branches && this.setState({ branches })
  }

  /**
   * Item Component
   * @param {Object} key .
   * @param {Object} item .
   * @return {void}
   */
  itemComponent = (key, item) => (
    <div
      key={key}
      className={styles.ItemContainer}
    >
      <p className={styles.KeyCenter}>{key}</p>
      <div
        className={styles.State}
      >
        {Array.isArray(item) && item.map((branch, index) => {
          const {
            Center_Name, Number, Neighborhood, Street, Timetable, Prev_Videocall, promo
          } = branch
          const { publicKey, patient } = this.props
          const country = patient.Country_Ops;
          const address = `${Street} ${Number}, ${Neighborhood}`
          if (Prev_Videocall && Prev_Videocall === true && (country !== 'Colombia' || country !== 'colombia')) {
            return (
              <Experiment id="lmO2jJwnRRKDDA88i8cKxw">
                <Variant id="0">
                  <div
                    className={[styles.CenterContainer, index === 0 ? styles.First : ''].join(' ')}
                    key={branch.Appointment_Type_Id}
                    role="button"
                    tabIndex={0}
                    onClick={() => this.trackEvent(branch)}
                  >
                    {branch.Center_Icon ? (
                      <img
                        className={styles.Icon}
                        alt="icon"
                        src={branch.Center_Icon}
                      />
                    ) : (
                      <img
                        className={styles.Icon}
                        alt="icon"
                        src={implant}
                      />
                    )}
                    <div className={styles.AddressContainer}>
                      <p className={styles.Center}>{Center_Name}</p>
                      <p className={styles.Address}>{address}</p>
                      <p className={styles.Schedule}>{Utils.parseTimeTable(Timetable)}</p>
                    </div>
                  </div>
                </Variant>
                <Variant id="1">
                  <Popup
                    onOpen={() => {
                      window.freshpaint.track('MX - popUpTelemedicine', { action: 'open' })
                    }}
                    trigger={(
                      <div
                        className={[styles.CenterContainer, index === 0 ? styles.First : ''].join(' ')}
                        key={branch.Appointment_Type_Id}
                        role="button"
                        tabIndex={0}
                      >
                        {branch.Center_Icon ? (
                          <img
                            className={styles.Icon}
                            alt="icon"
                            src={branch.Center_Icon}
                          />
                        ) : (
                          <img
                            className={styles.Icon}
                            alt="icon"
                            src={implant}
                          />
                        )}
                        <div className={styles.AddressContainer}>
                          <p className={styles.Center}>{Center_Name}</p>
                          <p className={styles.Address}>{address}</p>
                          <p className={styles.Schedule}>{Utils.parseTimeTable(Timetable)}</p>
                        </div>
                      </div>
                    )}
                    modal
                    nested
                    onClose={() => {
                      window.freshpaint.track('MX - popUpTelemedicine', { action: 'close' })
                      this.trackEvent(branch);
                    }}
                  >
                    {close => (
                      <div className="modal">
                        <div
                          className="close"
                          onClick={close}
                        >
                          &times;
                        </div>
                        <div className="content-wrapper">
                          <div className="modal-image">
                            <div>
                              <img
                                className="image"
                                src={modalImage}
                                alt="aligners"
                              />
                            </div>
                          </div>
                          <div className="content-texts">
                            <div className="header">
                              <p className="header-text">
                                {messages.modalTitle.one}
                                <br />
                                {messages.modalTitle.two}
                              </p>
                            </div>
                            <div className="content">
                              <p>
                                {messages.modalContent.one}
                                {/* replace centerName by the real name of the center */}
                                <span className="bold">{messages.modalContent.two.replace('centerName', Center_Name)}</span>
                                {messages.modalContent.three}
                              </p>
                            </div>
                            <div className="actions">
                              <div
                                className="cta"
                                onClick={() => {
                                  window.location.href = `https://precitas.mymoons.mx/${publicKey}`
                                }}
                              >
                                {messages.modalCTA}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup>
                </Variant>
              </Experiment>
            )
          }
          return (
            <div
              className={[styles.CenterContainer, index === 0 ? styles.First : ''].join(' ')}
              key={branch.Appointment_Type_Id}
              role="button"
              tabIndex={0}
              onClick={() => {
                this.trackEvent(branch);
              }}
            >
              {branch.Center_Icon ? (
                <img
                  className={styles.Icon}
                  alt="icon"
                  src={branch.Center_Icon}
                />
              ) : (
                <img
                  className={styles.Icon}
                  alt="icon"
                  src={implant}
                />
              )}
              <div className={styles.AddressContainer}>
                <p className={styles.Center}>{Center_Name}{Center_Name.includes('Polanco111')?<img className={styles.discount} alt="icon"src={discount} /> : null}</p>
                {promo ? <p className={styles.Promo}>{promo}</p>:null}
                <p className={styles.Address}>{address}</p>
                <p className={styles.Schedule}>{Utils.parseTimeTable(Timetable)}</p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )

  /**
   * Render
   * @returns {void}
   */
  render() {
    const { branches } = this.state
    const { name } = this.props

    const title = window.innerWidth < 700 ? messages.titleMobile : messages.titleDesktop
    const welcome = messages.welcome.replace('{Name}', name)

    return (
      <div className={styles.ContainerBranch}>
        <p className={styles.TitleName}>{welcome}</p>
        <p className={styles.Title}>{title}</p>
        {branches && !Utils.isEmptyObject(branches)
        && Object.entries(branches).map(([key, value]) => this.itemComponent(key, value))}
      </div>
    )
  }
}

export default withRouter(Branches)
