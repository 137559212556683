const messages = {
  map: 'Ver en maps',
  thanks: 'Gracias por agendar, ya queremos verte!',
  addCalendar: 'Agregar a mi calendario',
  recommendation: 'Recomendaciones',
  seeMaps: 'Ver en maps',
  newAppointment: 'REAGENDAR CITA',
  cancelAppointment: 'CANCELAR CITA',
  backToUserDashboard: 'Ir a mi cuenta',
  appointmentVideocall: 'Agendar videollamada',
  title: 'Platica con un experto de sonrisa Moons',
  description: 'Agenda una videollamada con uno de nuestros asesores de sonrisa que te explicará a detalle todo lo que necesitas saber sobre el tratamiento, proceso y métodos de financiamiento antes de que acudas a tu cita.'
}

export default messages
