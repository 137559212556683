/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react'
import moment from 'moment'
import styles from './Confirm.module.css'
import messages from './Confirm.messages'
import astronaut from '../../Assets/images/svg/astronaut.svg'
import LoaderSection from '../LoaderSection/LoaderSection'
import Utils from '../../Utils/utils'
import { Button, Dropdown } from '@mymoons/ui-library'
import { joiResolver } from '@hookform/resolvers/joi'
import Joi from 'joi'
import { useForm, Controller } from 'react-hook-form'
import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import Middleware from '../../Api/Middleware'
import I18n from '../../i18n'
import maintainUrlSearch from '../../Utils/maintain-url-search'

const getScheme = (type) => {
  return Joi.object({
    reasonDropdown: Joi.string()
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case 'string.empty':
            err.message = messages[type].reason
            break
          case 'any.empty':
            err.message = messages[type].reason
            break
          case 'any.required':
            err.message = messages[type].reasonRequired
            break
          default:
            break
        }
      })
      return errors
    }),
    additionalComments: Joi.string()
    .allow(null, '')
    .max(150)
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.code) {
          case 'any.empty':
            err.message = messages[type].reasonRequired
            break
          case 'string.empty':
            err.message = messages.additionalComments
            break
          default:
            break
        }
      })
      return errors
    })
  })
}

const optionsCancel = [
  { id: 'initial', value: '', label: 'Selecciona una opción' },
  { id: '1', value: 'Moving out', label: 'Cambio de residencia' },
  { id: '2', value: 'Disease', label: 'Temas de salud' },
  { id: '3', value: 'Treatment cost', label: 'Costo de tratamiento' },
  { id: '4', value: 'Reduced income', label: 'Reducción de ingresos' },
  { id: '5', value: 'Lack of time', label: 'Falta de disponibilidad en agenda' },
  { id: '6', value: 'Distrust', label: 'Desconfianza' },
  { id: '7', value: 'Another treatment ongoing', label: 'Ya cuento con otro tratamiento de alineación' },
  { id: '8', value: 'By Moons', label: 'Por Moons' },
]

const optionsReschedule = [
  { id: 'initial', value: '', label: 'Selecciona una opción' },
  { id: '1', value: 'Disease', label: 'Temas de salud' },
  { id: '2', value: 'Working hours', label: 'Horario laboral' },
  { id: '3', value: 'I forgot that I had scheduled', label: 'Se me olvidó que tenía cita' },
  { id: '4', value: 'Pending treatments', label: 'Tratamientos pendientes' },
  { id: '5', value: 'I won\'t be in town', label: 'Viaje' },
  { id: '6', value: 'Personal reasons', label: 'Motivos personales' },
  { id: '7', value: 'By Moons', label: 'Por Moons' },
]

/**
 * Confirm component.
 * @returns {void} .
 */
const Confirm = ({
  type, branch, dateSelected, userData, showModal, cancelAppointment, nextStep, timeZone, history, setReason, customerId,
  country, publicKey, journeyVariant
}) => {
  const [loader, setLoader] = useState(false)
  const [error, setError] = useState(false)
  const [isOpenReschedulerModal, setIsOpenReschedulerModal] = useState(false)
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false)
  const [isOpenCancelConfirmationModal, setIsOpenCancelConfirmationModal] = useState(false)
  const [reasonDropdown, setReasonDropdown] = useState()
  const [isShowComments, setIsShowComments] = useState(false)
  const [disabledBtn, setDisableBtn] = useState(true)
  const [errorComments, setErrorComments] = useState()
  const [refund, setRefund] = useState(false)
  const [repeat, setRepeat] = useState(true)

  let tries = 1

  const returnPolicyUrl = country === 'México' ? process.env.REACT_APP_RETURN_POLICY_URL_MX
  : country === 'Colombia' ? process.env.REACT_APP_RETURN_POLICY_URL_CO
  : country === 'Chile' ? process.env.REACT_APP_RETURN_POLICY_URL_CL
  : process.env.REACT_APP_RETURN_POLICY_URL_PE
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useForm({
    resolver: joiResolver(getScheme(type)),
  })

  const onFormSubmit = (data) => {
    let reasonParams = {
      customerId,
      reason: data.reasonDropdown
    }
    if (data.reasonDropdown === 'Other') {
      if (isEmpty(data.additionalComments)) {
        setErrorComments(messages.additionalComments)
        return
      }
      reasonParams.otherReason = data.additionalComments
    }
    setReason(reasonParams)
    if (type === 'reschedule') {
      reschedule()
    } else {
      cancel()
    }
  }
  /**
   * Cancel.
   * @param {Function} setLoader .
   * @returns {void} .
   */
  const cancel = async () => {
    setDisableBtn(true)
    setLoader(true)
    const response = await cancelAppointment()
    if (!response || response.error) {
      const errorText = response.message || messages[type].error
      setError(errorText)
      setDisableBtn(false)
    } else {
      checkAppointmentStatus()
    }
  }
  const redirectSupport = () => {
    const { country } = userData
    if (country === 'México') {
      window.open(
        `https://api.whatsapp.com/send?phone=525571000303&text=${messages[type].messageRefund}`,
        '_self'
      )
    } else {
      window.open(
        `https://api.whatsapp.com/send?phone=574899021&text=${messages[type].messageRefund}`,
        '_self'
      )
    }
  }
  const checkAppointmentStatus = () => {
    if(repeat && tries <= 3) {
      setTimeout(() => {
        Middleware.getTable(process.env.REACT_APP_MIDDLEWARE_ITEM, 'Patient', null, { PublicKey: publicKey }, Success, Error)
      }, 1000 * tries * 2)
    } else {
      setLoader(false)
      setIsOpenCancelConfirmationModal(true)
    }
  }
  const Success = (data) => {
    const patient = data.Patient
    if (patient && patient.Appointment_Status) {
      if (patient.Appointment_Status === "Canceled") {
        setLoader(false)
        setRepeat(false)
        setIsOpenCancelConfirmationModal(true)
      } else {
        tries++
        if (tries <= 3) {
          checkAppointmentStatus()
        } else {
          setLoader(false)
          setRepeat(false)
          setIsOpenCancelConfirmationModal(true)
        }
      }
    } else {
      setLoader(false)
      setRepeat(false)
      setIsOpenCancelConfirmationModal(true)
    }
  }
  const Error = (data) => {
    console.log(data)
    setRepeat(false)
  }
  const redirect = () => {
    let redirectUrl, search
    if (history && history.location && history.location.search) {
      search = queryString.parse(history.location.search)
      redirectUrl = search.redirectUrl
      if (redirectUrl) {
        history.push(`?redirectUrl=${encodeURIComponent(redirectUrl)}`)
      } else {
        history.push(maintainUrlSearch())
      }
    }
    if (redirectUrl) {
      window.open(redirectUrl, '_self')
    } else {
      window.location.reload()
    }
  }

  /**
   * Reschedule.
   * @param {Function} setLoader .
   * @returns {void} .
   */
  const reschedule = async () => {
    const goToTheSpecificStepReschedule = true;
    const isBack = false;
    nextStep(isBack, goToTheSpecificStepReschedule)
  }

  const i18n = new I18n(country)

  return (
    <div className={styles.ConfirmContainer}>
      {isOpenCancelConfirmationModal && (
        <div className={styles.PopUp}>
          <div className={styles.WrapFull}>
            <div className={styles.Content}>
              <img src={astronaut}/>
              <p className={styles.TitleCancel}>
                {messages.citaCancelada.title}
              </p>
              <p className={styles.Desc} style={{textAlign:'center'}}>
                {refund ? messages.citaCancelada.description1 : messages.citaCancelada.description0}
              </p>
              {refund && (
                <>
                  <p className={styles.DescSmall} style={{textAlign:'center'}}>
                    {messages.citaCancelada.description2}
                  </p>
                  <div className={styles.ModalCancelConfirmPolicy}>
                    <Button
                      color="dark"
                      label={messages.citaCancelada.buttonPolicy}
                      href={returnPolicyUrl}
                      target="_blank"
                      size="medium"
                      variant="textProtected"
                      fullWidth
                    />
                  </div>
                </>
              )}
              <div className={styles.ModalCancelConfirmSupport}>
                <Button
                  color="red"
                  label={refund ? messages.citaCancelada.buttonSupport : messages.citaCancelada.buttonContinue}
                  onClick={() => refund ? redirectSupport() : redirect()}
                  size="medium"
                  variant="filled"
                  fullWidth
                  className={styles.ButtonBlue}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {!isOpenCancelConfirmationModal && (
        <div className={styles.PopUp}>
          <div className={styles.WrapHeader}>
            <p className={styles.Title}>
              {messages[type].title}
            </p>
          </div>
          <div className={styles.Wrap}>
            <div className={styles.Content}>
              <p className={styles.Desc}>
              {journeyVariant === "card_holder"
                  ? messages[type].descriptionHolder
                  : messages[type].description}
              </p>
              <div
                className='info-modal'
                style={{ minHeight: 'initial', maxWidth: 664, width: '100%' }}
              >
                <div className='info-modal-container'>
                  <form onSubmit={handleSubmit(onFormSubmit)} className='fullwidth' noValidate>
                    <Dropdown
                      name='reasonDropdown'
                      fullWidth
                      error={errors?.reasonDropdown?.message}
                      label={messages[type].reasonTitle}
                      placeholder={messages.selectOption}
                      value={reasonDropdown}
                      onChange={(e) => {
                        setIsShowComments(e.value == 'Other')
                        setReasonDropdown(e)
                        setValue('reasonDropdown', e.value)
                        setDisableBtn(e.value ? false : true)
                      }}
                      options={type === 'reschedule' ? optionsReschedule : optionsCancel}
                    />
                    {isShowComments && (
                      <>
                      <label className={styles.AdditionalCommentsLabel}>Comentarios adicionales</label>
                        <Controller
                          name="additionalComments"
                          control={control}
                          render={({ field: { onChange, value, name } }) => (
                            <textarea
                              className={styles.AdditionalComments}
                              name={name}
                              label={messages.additionalComments}
                              placeholder={messages.maximumCharacters}
                              variant="outline"
                              value={value}
                              onChange={onChange}
                              maxLength={150}
                              pattern="(.|\n)*?"
                            />
                          )}
                        />
                        <p className={styles.ErrorComments}>{errorComments}</p>
                      </>
                    )}
                    {loader ? (
                      <div className={styles.ContainerLoader}>
                        <LoaderSection />
                      </div>
                    ) : (
                      <div className={styles.ModalRightButtons}>
                        <div className={styles.ModalRightButtonsConfirm}>
                          <Button
                            isButton
                            type="submit"
                            color="red"
                            label={messages[type].confirm}
                            size="medium"
                            variant="filled"
                            disabled={disabledBtn}
                            className={disabledBtn ? styles.ButtonBlueDisable : styles.ButtonBlue}
                          />
                        </div>
                        <div className={styles.ModalRightButtonsCancel}>
                          <Button
                            color="dark"
                            label={messages[type].cancel}
                            onClick={() => showModal()}
                            size="medium"
                            variant="outline"
                          />
                        </div>
                    </div>
                    )}
                  </form>
                  <a
                    className={styles.SupportButton}
                    target="_blank"
                    rel="noreferrer"
                    href={`${i18n.msg.socialUrl.whatsappSupport}&text=${messages[type].support}`}
                  >
                    Contactar a soporte
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Confirm
