import { ErrorException } from '../Utils/error-exception';

const { REACT_APP_API_URL} = process.env;

const callApi = async (customerId) => {
  try {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        customerId,
        "deal":{
            "Priority_Booking":"To Be Contacted"
        }
    }),
    };
    const response = await fetch(`${REACT_APP_API_URL}crm/blackbox`, options);
    return response.json();
  } catch (errorCallApi) {
    if (errorCallApi.message) {
      throw new ErrorException(
        errorCallApi.message,
        errorCallApi.name,
        errorCallApi.object,
      );
    } else {
      throw new ErrorException('Error in Call api func', 'Call api func', {
        errorCallApi,
      });
    }
  }
};

export const apiBlackBox = {
  Patient(
    customerId
  ) {
    try {
      return callApi(customerId);
    } catch (errorBlackBox) {
      console.error({ errorBlackBox });
      if (errorBlackBox.message) {
        throw new ErrorException(
          errorBlackBox.message,
          errorBlackBox.name,
          errorBlackBox.object,
        );
      } else {
        throw new ErrorException(
          'Error general in function apiBlackBox',
          'apiBlackbox',
          { errorBlackBox },
        );
      }
    }
  },
};
