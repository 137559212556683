const messages = {
  titleDesktop: 'Selecciona tu centro más cercano, para escanear tu sonrisa en 3D ',
  titleMobile: 'Selecciona tu centro más cercano ',
  welcome: '{Name}, la sonrisa de tus sueños esta cerca',
  modalTitle: {
    one: 'Agenda tu',
    two: 'videollamada GRATIS'
  },
  modalContent: {
    one: 'El centro que seleccionaste en ',
    // centerName will be replace by the real name of branch (center)
    two: 'centerName es un centro aliado, ',
    three: 'si tienes alguna duda puedes agendar una videollamada con alguno de nuestros expertos para resolver todas tus dudas.'
  },
  modalCTA: 'AGENDAR LLAMADA'
}

export default messages
