const MaintainUrlSearch = () => {
  if (window.location.search) {
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)
    params.delete('rescheduleId')
    return `?${params}`
  }
  return '?'
}

export default MaintainUrlSearch
