/* eslint-disable react/jsx-no-literals */
import React from 'react'
import I18n from '../../i18n'
import style from './Footer.module.css'
import facebook from '../../Assets/images/png/facebook-f.png'
import whatsapp from '../../Assets/images/png/whatsapp.png'
import youtube from '../../Assets/images/png/youtube.png'
import instagram from '../../Assets/images/png/instagram.png'

/**
 * Footer Component
 * @returns {void} .
 */
const Footer = ({ country }) => {
  const i18n = new I18n(country)
  return (
    <div className={style.FooterContainer}>
      <a
        className={style.Left}
        rel="noopener noreferrer"
        target="_blank"
        href={i18n.msg.socialUrl.whatsapp}
      >
        <img
          className={style.Icon}
          alt="whatsapp"
          src={whatsapp}
        />
        <p className={style.Mobile}>{i18n.msg.phone.number}</p>
      </a>
      <div className={style.Right}>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={i18n.msg.socialUrl.facebook}
        >
          <img
            className={style.Facebook}
            alt="facebook"
            src={facebook}
          />
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={i18n.msg.socialUrl.instagram}
        >
          <img
            className={style.Instagram}
            alt="instagram"
            src={instagram}
          />
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={i18n.msg.socialUrl.youtube}
        >
          <img
            className={style.Icon}
            alt="youtube"
            src={youtube}
          />
        </a>
      </div>
    </div>
  )
}

export default Footer
