/* eslint-disable quote-props */
/* eslint-disable no-unused-vars */
export const CENTER_STEP = 'center'
export const DATE_STEP = 'date'
export const ACCEPT_STEP = 'accept'
export const SUCCESS_STEP = 'success'
export const CHECKOUT_STEP = 'checkout'
export const FREE_APPOINMENT_VARIANT_MEDELLIN = 'freeMedellin'
export const FREE_APPOINMENT_VARIANT_BUCARAMANGA = 'freeBucaramanga'
export const PROMO_APPOINMENT_VARIANT_MEDELLIN = 'promoMedellin'
export const PROMO_APPOINMENT_VARIANT_BUCARAMANGA = 'promoBucaramanga'

export const servicesList = [
  'limpieza',
  'carillas',
  'blanqueamiento',
  'implantes',
]

export const variantsBySource = {
  México: {
    'default': 'cita',
  },
  Colombia: {
    'default': 'cita',
  },
  Chile: {
    'cl_facebook5900': 'cita',
    'cl_facebook7000': 'cita7000',
    'cl_google7000': 'cita7000',
    'cl_google10900': 'cita10900',
    'default': 'cita',
  },
  Peru: {
    'default': 'cita',
  }
}
