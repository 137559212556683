import React, { Component } from 'react'

/**
 * TimeCounter Component
 * @returns {void} .
 */
class RedirectSuccess extends Component {

  componentDidMount(){
    window.location.href = this.props.url
  }

  /**
   * RedirectSuccess render
   * @returns {void} .
   */
  render() {
    return (<></>)
  }
}

export default RedirectSuccess
